import React, { useEffect, useState } from "react";
import logo from "./logo.png";
import "./App.css";
import "./App.css"; // Import additional styles
import { initializePaddle } from "@paddle/paddle-js";

function App() {
  // Create a local state to store Paddle instance
  const [paddle, setPaddle] = useState();

  // Download and initialize Paddle instance from CDN
  useEffect(() => {
    initializePaddle({
      token: "live_56c5b1e9a349fcb88917b540543",
      eventCallback: function (data) {
        // The data.event will specify the event type
        if (data.event === "Checkout.Complete") {
          console.log(data.eventData); // Data specifics on the event
        } else if (data.event === "Checkout.Close") {
          console.log(data.eventData); // Data specifics on the event
        }
      },
    }).then((paddleInstance) => {
      if (paddleInstance) {
        setPaddle(paddleInstance);
      }
    });
  }, []);

  // Callback to open a checkout
  const openCheckout = () => {
    // Get query parameters from the URL
    const urlParams = new URLSearchParams(window.location.search);

    // Extract product IDs and quantities from the query parameters
    const products = urlParams.getAll("productId[]");
    const quantities = urlParams.getAll("quantity[]");

    // Create an array of items
    const items = products.map((productId, index) => ({
      priceId: productId,
      quantity: parseInt(quantities[index]) || 1,
    }));

    // Open the checkout with the array of items
    paddle?.Checkout.open({
      items,
    });
  };

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />

        <h1 className="App-title">Welcome to SpixHost Payment</h1>

        <button className="App-button" onClick={openCheckout}>
          Pay Now!
        </button>
      </header>
      <div className="checkout-container"></div>
    </div>
  );
}

export default App;
